import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import bgImage from '../assets/videoBackground.png'


const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 60,
  },
  row: {
    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  figure: {
    maxWidth: 300,
    margin: "30px auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.itemsContainer}
        >
          {props.items.map((item, index) => (
            <Grid
              className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
            >
              <Grid item={true} xs={12} md={6}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.link != null ?
                        <a  style={{textDecoration: 'none', color: 'inherit'}} href={item.link.toString()}>
                          {item.description}
                        </a> :
                        item.description
                    }
                  </Typography>
                </Box>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <figure className={classes.figure} >
                  {item.link != null ?
                      <a href={item.link.toString()}>
                        <img
                            style={item.class != null ? {} : { padding: '10%', maxWidth: '100%', backgroundRepeat: 'no-repeat',backgroundPosition: 'center', backgroundSize: '75%', backgroundImage: `url(${bgImage.toString()})`}}
                            src={item.image}
                            alt={item.title}
                            className={classes.image}
                        />
                      </a> :
                      <img
                          style={item.class != null ? {} : { padding: '10%', maxWidth: '100%', backgroundRepeat: 'no-repeat',backgroundPosition: 'center', backgroundSize: '75%', backgroundImage: `url(${bgImage.toString()})`}}
                          src={item.image}
                          alt={item.title}
                          className={classes.image}
                      />
                  }

                </figure>
              </Grid>
            </Grid>
          ))}
        </Container>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
