import React, {useEffect, useState} from "react";
import ServiceSection from "../components/ServiceSection";
import {useTranslation} from "react-i18next";
import sticker from "../assets/service_sticker.png";
import htv from "../assets/service_htv.png";
import foodTruck from "../assets/food_truck.png";
import apartments from "../assets/apartment.png";
import banner from "../assets/banner.png";
import interactiveArt from "../assets/interactive_art.png";
import metal from "../assets/metal.png";
import storeFront from "../assets/storefront.png";
import trailer from "../assets/trailer.png";
import Container from "@material-ui/core/Container";
import ExampleDialog from "../components/ExampleDialog";


function Services(props) {
    const { t } = useTranslation();
    const [examplesToLoad, setExamplesToLoad] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogIsOpen,setDialogIsOpen] = useState(false);

    useEffect(() => {
        setDialogIsOpen(openDialog)
    }, [openDialog]);

    useEffect(() => {
        setOpenDialog(dialogIsOpen)
    }, [dialogIsOpen]);

    const serviceArray = [
        {avatar: storeFront, id: 'store_front'},
        {avatar: sticker, id: 'sticker'},
        {avatar: banner, id: 'banner'},
        {avatar: trailer, id: 'trailer'},
        {avatar: htv, id: 'htv'},
        {avatar: interactiveArt, id: 'interactive_art'},
        {avatar: metal, id: 'metal'},
        {avatar: foodTruck, id: 'food_truck'},
        {avatar: apartments, id: 'apartments'},
    ]
    return (
        <Container>
            {dialogIsOpen && (
                <ExampleDialog
                    dialogIsOpen={dialogIsOpen => setDialogIsOpen(dialogIsOpen)}
                    examplesToLoad={examplesToLoad}
                />
            )}
            <ServiceSection
                examplesToLoad={examplesToLoad => setExamplesToLoad(examplesToLoad)}
                openDialog={openDialog => setOpenDialog(openDialog)}
                bgColor="light"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                title={t('services.title')}
                subtitle=""
                serviceList={serviceArray.map(service => ({
                    avatar: service.avatar,
                    name: t(`services.list.${service.id}.title`),
                    testimonial: t(`services.list.${service.id}.description`),
                    id: service.id,
                }))}
            />
        </Container>
    );
}

export default Services;
