import React from "react";
import LongContent from "./LongContent";
import { Trans, useTranslation } from "react-i18next";
import List from "@material-ui/core/List";
import {ListItem} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { HashLink as Link } from 'react-router-hash-link';

function LegalTerms(props) {
    const {t} = useTranslation();
    const basePath = '/legal/terms-of-service';

    const toc = (
        <List dense>
            <ListItem>
                <Link to={`${basePath}/#toc1`}><ListItemText primary={t('terms.table_of_contents.one')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc2`}><ListItemText primary={t('terms.table_of_contents.two')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc3`}><ListItemText primary={t('terms.table_of_contents.three')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc4`}> <ListItemText primary={t('terms.table_of_contents.four')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc5`}><ListItemText primary={t('terms.table_of_contents.five')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc6`}><ListItemText primary={t('terms.table_of_contents.six')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc7`}> <ListItemText primary={t('terms.table_of_contents.seven')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc8`}> <ListItemText primary={t('terms.table_of_contents.eight')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc9`}><ListItemText primary={t('terms.table_of_contents.nine')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc10`}><ListItemText primary={t('terms.table_of_contents.ten')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc11`}><ListItemText primary={t('terms.table_of_contents.eleven')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc12`}><ListItemText primary={t('terms.table_of_contents.twelve')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc13`}><ListItemText primary={t('terms.table_of_contents.thirteen')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc14`}> <ListItemText primary={t('terms.table_of_contents.fourteen')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc15`}> <ListItemText primary={t('terms.table_of_contents.fifteen')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc16`}><ListItemText primary={t('terms.table_of_contents.sixteen')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc17`}> <ListItemText primary={t('terms.table_of_contents.seventeen')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc18`}><ListItemText primary={t('terms.table_of_contents.eighteen')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc19`}><ListItemText primary={t('terms.table_of_contents.nineteen')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc20`}> <ListItemText primary={t('terms.table_of_contents.twenty')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc21`}> <ListItemText primary={t('terms.table_of_contents.twenty_one')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc22`}><ListItemText primary={t('terms.table_of_contents.twenty_two')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc23`}><ListItemText primary={t('terms.table_of_contents.twenty_three')} /></Link>
            </ListItem>
            <ListItem>
                <Link to={`${basePath}/#toc24`}> <ListItemText primary={t('terms.table_of_contents.twenty_four')} /></Link>
            </ListItem>

        </List>
    );
    const sectionFour = (
        <>
            <Trans i18nKey="terms.section_four.description" />
            <List dense>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.one")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.two")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.three")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.four")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.five")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.six")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.seven")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.eight")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.nine")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.ten")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.eleven")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.twelve")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.thirteen")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.fourteen")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.fifteen")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.sixteen")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.seventeen")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.eighteen")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.nineteen")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.twenty")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.twenty_one")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.twenty_two")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_four.list.twenty_three")} />
                </ListItem>
            </List>
        </>
    );
    const sectionFive = (
        <>
            <Trans i18nKey="terms.section_five.description" />
            <List dense>
                <ListItem>
                    <ListItemText primary={t("terms.section_five.list.one")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_five.list.two")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_five.list.three")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_five.list.four")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_five.list.five")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_five.list.six")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_five.list.seven")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_five.list.eight")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_five.list.nine")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_five.list.ten")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_five.list.eleven")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_five.list.twelve")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("terms.section_five.list.thirteen")} />
                </ListItem>
            </List>
            <Trans i18nKey="terms.section_five.footer" />

        </>
    );
  return (
      <LongContent>
          <h1>{t('terms.title')}</h1>

          <h4>{t('terms.updated')}</h4>

          <h2>{t('terms.table_of_contents_title')}</h2>
          <div>
              {toc}
          </div>
          <h2 id="toc1">{t('terms.section_one.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_one.description" />
          </div>
          <h2 id="toc2">{t('terms.section_two.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_two.description" />
          </div>
          <h2 id="toc3">{t('terms.section_three.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_three.description" />
          </div>
          <h2 id="toc4">{t('terms.section_four.title')}</h2>
          <div className="section_indent">
              {sectionFour}
          </div>
          <h2 id="toc5">{t('terms.section_five.title')}</h2>
          <div className="section_indent">
              {sectionFive}
          </div>
          <h2 id="toc6">{t('terms.section_six.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_six.description" />
          </div>
          <h2 id="toc7">{t('terms.section_seven.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_seven.description" />
          </div>
          <h2 id="toc8">{t('terms.section_eight.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_eight.description" />
          </div>
          <h2 id="toc9">{t('terms.section_nine.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_nine.description" />
          </div>
          <h2 id="toc10">{t('terms.section_ten.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_ten.description" />
          </div>
          <h2 id="toc11">{t('terms.section_eleven.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_eleven.description" />
          </div>
          <h2  id="toc12">{t('terms.section_twelve.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_twelve.description" />
          </div>
          <h2  id="toc13">{t('terms.section_thirteen.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_thirteen.description" />
          </div>
          <h2  id="toc14">{t('terms.section_fourteen.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_fourteen.description" />
          </div>
          <h2  id="toc15">{t('terms.section_fifteen.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_fifteen.description" />
          </div>
          <h2  id="toc16">{t('terms.section_sixteen.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_sixteen.description" />
          </div>
          <h2 id="toc17">{t('terms.section_seventeen.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_seventeen.description" />
          </div>
          <h2 id="toc18">{t('terms.section_eighteen.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_eighteen.description" />
          </div>
          <h2 id="toc19">{t('terms.section_nineteen.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_nineteen.description" />
          </div>
          <h2 id="toc20">{t('terms.section_twenty.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_twenty.description" />
          </div>
          <h2 id="toc21">{t('terms.section_twenty_one.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_twenty_one.description" />
          </div>
          <h2 id="toc22">{t('terms.section_twenty_two.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_twenty_two.description" />
          </div>
          <h2 id="toc23">{t('terms.section_twenty_three.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_twenty_three.description" />
          </div>
          <h2 id="toc24">{t('terms.section_twenty_four.title')}</h2>
          <div className="section_indent">
              <Trans i18nKey="terms.section_eight.description" />
          </div>
      </LongContent>
  );
}

export default LegalTerms;
