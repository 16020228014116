import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import TeamBiosSection from "./../components/TeamBiosSection";
import { useTranslation } from "react-i18next";
import bkgi from "../assets/bckg.png"


function AboutPage(props) {
    const { t } = useTranslation()
  return (
    <>
      <Meta title={t('about.title')} description={t('about.description')} />
      <HeroSection2
        bgColor="default"
        size="large"
        bgImage={bkgi}
        bgImageOpacity={0.4}
        title={t('about.heading')}
        subtitle={t('about.subheading')}
      />
      <TeamBiosSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />
    </>
  );
}

export default AboutPage;
