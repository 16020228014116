import React, {useState} from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import furnishedImage from "../assets/storefront_1_1.png";
import featureImage from "../assets/storefront_2.png";


const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    margin: "0 auto",
    maxWidth: 570,
    height: "auto",
    width: "100%",
    borderRadius: "3px",
  },
  hover: {
      opacity:1,
      transition: "width 0.5s, height 0.5s, opacity 0.5s 0.5s",
    },
}));

function HeroSection(props) {
  const classes = useStyles();
  const [mainImage, setMainImage] = useState(furnishedImage);


  function handleMouseEnter(imagePath) {
    return () => {
      setMainImage(imagePath);
    };
  }

  const heroImage = () => {
    if (props.override === true) {
      return (
          <img
              className={classes.image}
              src={mainImage.toString()}
              alt="illustration"
              onMouseOver={handleMouseEnter(featureImage.toString())}
              onMouseOut={handleMouseEnter(furnishedImage.toString())}
          />
      )
    }
    return (
        <img
        src={props.image}
        alt="illustration"
        className={classes.image}
    />)
  }

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={6}>
          <Grid container={true} item={true} direction="column" xs={12} md={6}>
            <Box textAlign={{ xs: "center", md: "left" }}>
              <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                size={4}
              />
              <Button
                component={Link}
                to={props.buttonPath}
                variant="contained"
                size="large"
                color={props.buttonColor}
              >
                {props.buttonText}
              </Button>
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={true}>
            <figure >
              {heroImage()}
            </figure>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default HeroSection;
