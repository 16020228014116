import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import SectionHeader from "./SectionHeader";
import Image from 'mui-image';
import { useDarkMode } from "../util/theme";


function ServiceSection(props) {
    const isDark = useDarkMode();
    const handleClick = (e) => {
        props.openDialog(true);
        props.examplesToLoad(e);
    }

    return (

            <Container style={{paddingBottom: '20px'}}>
                <SectionHeader
                    title={props.title}
                    subtitle={props.subtitle}
                    size={4}
                    style={{marginBottom: '50px'}}
                    textAlign="center"
                />
                <Grid container={true} justifyContent="center" spacing={4}>
                    {props.serviceList.map((item, index) => (
                        <Grid item={true} xs={12} sm={3} key={index} onClick={(e) => {
                            handleClick(item.id);
                        }}>
                            <Card style={{borderRadius: '10px'}}>
                                <Box id={item.id} style={{marginTop: '10px', marginBottom: '10px', textAlign: 'center'}}

                                >
                                    <Typography
                                        component="p"
                                        style={
                                            isDark.value === true ? {
                                                fontSize: '23px',
                                                color: '#fff',
                                                fontWeight:'700',
                                                textShadow: '0px -1px 0 #89090F, 1px -1px 0 #89090F, -1px 1px 0 #89090F, 1px 1px 0 #89090F', verticalAlign: 'middle' } : {
                                                fontSize: '23px',
                                                color: '#89090F',
                                                fontWeight:'700',
                                                verticalAlign: 'middle'}
                                    }>
                                        {item.name}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="center" pt={3}>
                                <Image
                                    src={item.avatar}
                                    height="90%"
                                    width="90%"
                                    fit="contain"
                                    duration={1250}
                                    easing='cubic-bezier(0.7, 0, 0.6, 1)'
                                showLoading={ true }
                                errorIcon={ true }
                                shift={null}
                                distance="100px"
                                shiftDuration={900}
                                bgColor="inherit"
                                />
                                    </Box>
                                    <CardContent>
                                    <Box textAlign="center">
                                        <Typography variant="body1" component="p">
                                            {item.testimonial}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
    );
}

export default ServiceSection;
