import React, {useState} from "react";
import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import storefront_1 from "../assets/examples/storefront/store_front_1.png";
import storefront_2 from "../assets/examples/storefront/store_front_2.png";
import storefront_3 from "../assets/examples/storefront/store_front_3.png";
import storefront_4 from "../assets/examples/storefront/store_front_4.png";
import storefront_5 from "../assets/examples/storefront/store_front_5.png";
import storefront_6 from "../assets/examples/storefront/store_front_6.png";
import storefront_7 from "../assets/examples/storefront/store_front_7.png";
import storefront_8 from "../assets/examples/storefront/store_front_8.png";
import storefront_9 from "../assets/examples/storefront/store_front_9.png";
import storefront_10 from "../assets/examples/storefront/store_front_10.png";
import storefront_11 from "../assets/examples/storefront/store_front_11.png";
import storefront_12 from "../assets/examples/storefront/store_front_12.png";
import storefront_13 from "../assets/examples/storefront/store_front_13.png";
import Container from "@material-ui/core/Container";
import {Dialog, DialogContent } from "@mui/material";
import Button from "@material-ui/core/Button";


function Examples(props) {
    const storeFrontExamples = [
        {urlPath: storefront_1, alt: "Monster World Vertical", id: 1},
        {urlPath: storefront_2, alt: "Monster World Vertical 2", id: 2},
        {urlPath: storefront_3, alt: "Earth Illuminated 1"},
        {urlPath: storefront_4, alt: "Earth Illuminated 1"},
        {urlPath: storefront_5, alt: "Black Sheep"},
        {urlPath: storefront_6, alt: "The Tallent Company"},
        {urlPath: storefront_7, alt: "Dominics"},
        {urlPath: storefront_8, alt: "Johnstown Breeze"},
        {urlPath: storefront_9, alt: "Ice Rink"},
        {urlPath: storefront_10, alt: "Ice Rink 2"},
        {urlPath: storefront_11, alt: "Wellness Spring"},
        {urlPath: storefront_12, alt: "Monster World"},
        {urlPath: storefront_13, alt: "Monster World 2"},
    ];
    const [dialogIsOpen,setDialogIsOpen] = useState(false);
    const handleClose = () => {
        setDialogIsOpen(false);
    };
    return (
    <Container style={dialogIsOpen ? { zIndex:'1', filter: 'blur(1px)'} : {}}>
        <Button variant="text" onClick={() => {
            setDialogIsOpen(true);
        }}>Text</Button>

        <Dialog fullWidth={true}
                maxWidth='md' open={dialogIsOpen}
                onClose={handleClose}
        >
            <DialogContent style={{height: '100%', backgroundColor:'#240000'}}>
                <AwesomeSlider
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                    }}
                    bullets={false}
                    animation="foldOutAnimation"
                    cssModule={[CoreStyles, AnimationStyles]}
                >
                    {storeFrontExamples.map((example, index) => (
                            <div  data-src={example.urlPath} />
                        )
                    )}
                </AwesomeSlider>
            </DialogContent>
        </Dialog>
    </Container>
    );
}

export default Examples;
