import React from "react";
import LongContent from "./LongContent";
import { Trans, useTranslation } from "react-i18next";
import List from "@material-ui/core/List";
import {ListItem} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { HashLink as Link } from 'react-router-hash-link';


function LegalPrivacy(props) {
    const {t} = useTranslation();
    const basePath = '/legal/privacy-policy';

    const toc = (
    <List dense>
        <ListItem>
            <Link to={`${basePath}/#toc1`}><ListItemText primary={t('privacy.table_of_contents.one')} /></Link>
        </ListItem>
        <ListItem>
            <Link to={`${basePath}/#toc2`}><ListItemText primary={t('privacy.table_of_contents.two')} /></Link>
        </ListItem>
        <ListItem>
            <Link to={`${basePath}/#toc3`}><ListItemText primary={t('privacy.table_of_contents.three')} /></Link>
        </ListItem>
        <ListItem>
            <Link to={`${basePath}/#toc4`}><ListItemText primary={t('privacy.table_of_contents.four')} /></Link>
        </ListItem>
        <ListItem>
            <Link to={`${basePath}/#toc5`}><ListItemText primary={t('privacy.table_of_contents.five')} /></Link>
        </ListItem>
        <ListItem>
            <Link to={`${basePath}/#toc6`}><ListItemText primary={t('privacy.table_of_contents.six')} /></Link>
        </ListItem>
        <ListItem>
            <Link to={`${basePath}/#toc7`}><ListItemText primary={t('privacy.table_of_contents.seven')} /></Link>
        </ListItem>
        <ListItem>
            <Link to={`${basePath}/#toc8`}><ListItemText primary={t('privacy.table_of_contents.eight')} /></Link>
        </ListItem>
        <ListItem>
            <Link to={`${basePath}/#toc9`}><ListItemText primary={t('privacy.table_of_contents.nine')} /></Link>
        </ListItem>
        <ListItem>
            <Link to={`${basePath}/#toc10`}><ListItemText primary={t('privacy.table_of_contents.ten')} /></Link>
        </ListItem>
        <ListItem>
            <Link to={`${basePath}/#toc11`}><ListItemText primary={t('privacy.table_of_contents.eleven')} /></Link>
        </ListItem>
        <ListItem>
            <Link to={`${basePath}/#toc12`}><ListItemText primary={t('privacy.table_of_contents.twelve')} /></Link>
        </ListItem>
        <ListItem>
            <Link to={`${basePath}/#toc13`}><ListItemText primary={t('privacy.table_of_contents.thirteen')} /></Link>
        </ListItem>
    </List>
    );

    const sensitiveInfo = (
        <>
            <Trans i18nKey="privacy.summary_sections.sensitive_description" />
            <p>
                <strong>{t('privacy.summary_sections.third_parties_title')}</strong>
                <Trans i18nKey="privacy.summary_sections.third_parties_description" />
            </p>
            <p>
                <strong>{t('privacy.summary_sections.process_info_title')}</strong>
                <Trans i18nKey="privacy.summary_sections.process_info_description" />
            </p>
            <p>
                <strong>{t('privacy.summary_sections.share_title')}</strong>
                <Trans i18nKey="privacy.summary_sections.share_description" />
            </p>
            <p>
                <strong>{t('privacy.summary_sections.safe_title')}</strong>
                <Trans i18nKey="privacy.summary_sections.safe_description" />
            </p>
            <p>
                <strong>{t('privacy.summary_sections.rights_title')}</strong>
                <Trans i18nKey="privacy.summary_sections.rights_description" />
            </p>
            <p>
                <strong>{t('privacy.summary_sections.exercise_rights_title')}</strong>
                <Trans i18nKey="privacy.summary_sections.exercise_rights_description" />
            </p>
        </>
);
    const sectionOne = (
        <>
            <Trans i18nKey="privacy.section_one.subtitle" />
            <p>
                <Trans i18nKey="privacy.section_one.short"/><
                /p>
            <p>
                <Trans i18nKey="privacy.section_one.sub1" />
            </p>
            <div>
                <Trans i18nKey="privacy.section_one.sub2" />
            </div>
            <List dense>
                <ListItem>
                    <ListItemText primary={t("privacy.section_one.list.one")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("privacy.section_one.list.two")} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t("privacy.section_one.list.three")} />
                </ListItem>
            </List>
            <div>
                <Trans i18nKey="privacy.section_one.sub3" />
            </div>
            <List dense>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_one.sub3_list.one"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_one.sub3_list.two"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_one.sub3_list.three"/>} />
                </ListItem>
            </List>

        </>
    );

    const sectionTwo = (
        <>
            <Trans i18nKey="privacy.section_two.description" />
            <List dense>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_two.description_list.one"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_two.description_list.two"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_two.description_list.three"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_two.description_list.four"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_two.description_list.five"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_two.description_list.six"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_two.description_list.seven"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_two.description_list.eight"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_two.description_list.nine"/>} />
                </ListItem>
            </List>
        </>
    );

    const sectionThree = (
        <>
            <Trans i18nKey="privacy.section_three.description" />
            <List dense>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_three.description_list.one"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_three.description_list.two"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_three.description_list.three"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_three.description_list.four"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_three.description_list.five"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_three.description_list.six"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_three.description_list.seven"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_three.description_list.eight"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_three.description_list.nine"/>} />
                </ListItem>
            </List>
        </>
    );

    const sectionFour = (
        <>
            <Trans i18nKey="privacy.section_four.description" />
            <List dense>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_four.list_one.one"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_four.list_one.two"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_four.list_one.three"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_four.list_one.four"/>} />
                </ListItem>
            </List>
            <Trans i18nKey="privacy.section_four.sub1" />
            <List dense>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_four.list_two.one"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_four.list_two.two"/>} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={<Trans i18nKey="privacy.section_four.list_two.three"/>} />
                </ListItem>
            </List>

        </>
    );
  return (
    <LongContent>
      <h1>{t('privacy.title')}</h1>
      <h4>{t('privacy.updated')}</h4>
        <div>{t('privacy.body')}</div>
        <ul>
            <li>{t('privacy.body_list.one')}</li>
            <li>{t('privacy.body_list.two')}</li>
        </ul>
        <Trans i18nKey="privacy.questions" />
        <h2>{t('privacy.summary_title')}</h2>
       <div className="section_indent">
           <Trans i18nKey="privacy.summary_description" />
       </div>
        <h2>{t('privacy.summary_sections.personal_title')}</h2>
        <div className="section_indent">
            <Trans i18nKey="privacy.summary_sections.personal_description" />
        </div>
        <h2>{t('privacy.summary_sections.sensitive_title')}</h2>
        <div className="section_indent">
            {sensitiveInfo}
        </div>
        <h2>{t('privacy.table_of_contents_title')}</h2>
        <div>
            {toc}
        </div>
        <h2 id="toc1">{t('privacy.section_one.title')}</h2>
        <div className="section_indent">
            {sectionOne}
        </div>
        <h2 id="toc2">{t('privacy.section_two.title')}</h2>
        <div className="section_indent">
            {sectionTwo}
        </div>
        <h2 id="toc3">{t('privacy.section_three.title')}</h2>
        <div className="section_indent">
            {sectionThree}
        </div>
        <h2 id="toc4">{t('privacy.section_four.title')}</h2>
        <div className="section_indent">
            {sectionFour}
        </div>
        <h2 id="toc5">{t('privacy.section_five.title')}</h2>
        <div className="section_indent">
            <Trans i18nKey="privacy.section_five.description" />
        </div>
        <h2 id="toc6">{t('privacy.section_six.title')}</h2>
        <div className="section_indent">
            <Trans i18nKey="privacy.section_six.description" />
        </div>
        <h2 id="toc7">{t('privacy.section_seven.title')}</h2>
        <div className="section_indent">
            <Trans i18nKey="privacy.section_seven.description" />
        </div>
        <h2 id="toc8">{t('privacy.section_eight.title')}</h2>
        <div className="section_indent">
            <Trans i18nKey="privacy.section_eight.description" />
        </div>
        <h2 id="toc9">{t('privacy.section_nine.title')}</h2>
        <div className="section_indent">
            <Trans i18nKey="privacy.section_nine.description" />
        </div>
        <h2 id="toc10">{t('privacy.section_ten.title')}</h2>
        <div className="section_indent">
            <Trans i18nKey="privacy.section_ten.description" />
        </div>
        <h2 id="toc11">{t('privacy.section_eleven.title')}</h2>
        <div className="section_indent">
            <Trans i18nKey="privacy.section_eleven.description" />
        </div>
        <h2 id="toc12">{t('privacy.section_twelve.title')}</h2>
        <div className="section_indent">
            <Trans i18nKey="privacy.section_twelve.description" />
        </div>
        <h2 id="toc13">{t('privacy.section_thirteen.title')}</h2>
        <div className="section_indent">
            <Trans i18nKey="privacy.section_thirteen.description" />
        </div>

    </LongContent>
  );
}

export default LegalPrivacy;
