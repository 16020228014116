import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";
import { Trans } from "react-i18next";

function ContactPage(props) {

  return (
    <>
      <Meta title="Contact" />
      <ContactSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={props.quote === true ? <Trans i18nKey='quote.title'/> : <Trans i18nKey='contact_us.title'/>}
        subtitle={props.quote === true ?<Trans i18nKey='quote.form_description'/>: <Trans i18nKey='contact_us.form_description'/>}
        buttonText={props.quote === true ? <Trans i18nKey='quote.button'/> : <Trans i18nKey='contact_us.button'/>}
        buttonColor="primary"
        showNameField={true}
        description={props.quote === true ? <Trans i18nKey='quote.form_description'/> : <Trans i18nKey='contact_us.form_description'/>}
      />
    </>
  );
}

export default ContactPage;
