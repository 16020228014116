import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
// import ClientsSection from "./../components/ClientsSection";
import FeaturesSection from "./../components/FeaturesSection";
// import TestimonialsSection from "./../components/TestimonialsSection";
// import Alert from "@material-ui/lab/Alert";
import { useTranslation } from 'react-i18next';
import whoAreWe from "../assets/whoarewe.png";
import videoGif from "../assets/loops.gif";
import vapor from "../assets/vape_web.png";
import jobExamples from "../assets/examples.png";

function IndexPage(props) {
    const { t } = useTranslation();
    const featureArray = [{
            title: t('home.feature.who.title'),
            class: 'image',
            description: t('home.feature.who.description'),
            image: `${whoAreWe.toString()}`,
            link: null,
        },
        {
            title: t('home.feature.where.title'),
            class: null,
            description: t('home.feature.where.description'),
            image: `${videoGif.toString()}`,
            link: null,
        },
        {
            title: t('home.feature.vape.title'),
            class: 'image',
            description:t('home.feature.vape.description'),
            image: `${vapor.toString()}`,
            link: null,
        },
        {
            title: t('home.feature.past_work.title'),
            class: 'image',
            description:t('home.feature.past_work.description'),
            image: `${jobExamples.toString()}`,
            link: "/examples",
    },
];
  return (
    <>
      <Meta />
        {/*<div style={{alignSelf: 'center', maxWidth: '70%'}}>*/}
        {/*    <Alert severity="info">{t('maintenance')}</Alert>*/}
        {/*</div>*/}
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={t('home.title')}
        subtitle={t('home.subtitle')}
        image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
        override={true}
        buttonText={t('home.button')}
        buttonColor="primary"
        buttonPath="/contact"
      />
      {/*<ClientsSection*/}
      {/*  bgColor="light"*/}
      {/*  size="normal"*/}
      {/*  bgImage=""*/}
      {/*  bgImageOpacity={1}*/}
      {/*  title=""*/}
      {/*  subtitle=""*/}
      {/*/>*/}
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={t('home.feature.title')}
        subtitle={t('home.feature.subtitle')}
        items={featureArray}
      />
      {/*<TestimonialsSection*/}
      {/*  bgColor="light"*/}
      {/*  size="medium"*/}
      {/*  bgImage=""*/}
      {/*  bgImageOpacity={1}*/}
      {/*  title="Here's what people are saying"*/}
      {/*  subtitle=""*/}
      {/*/>*/}
      {/*<NewsletterSection*/}
      {/*  bgColor="default"*/}
      {/*  size="medium"*/}
      {/*  bgImage=""*/}
      {/*  bgImageOpacity={1}*/}
      {/*  title="Wanna Subscribe?"*/}
      {/*  subtitle="Email newsletters aren't really our thing but in the off chance that we start, this is where you'd subscribe."*/}
      {/*  buttonText="Subscribe"*/}
      {/*  buttonColor="primary"*/}
      {/*  inputPlaceholder="Enter your email"*/}
      {/*  subscribedMessage="You are now subscribed!"*/}
      {/*/>*/}
    </>
  );
}

export default IndexPage;
