import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
// import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import { ThemeProvider } from "./../util/theme";
import logoDark from "../assets/vs_logo_light_red.svg";
import logoLight from "../assets/vs_logo_dark_red.svg";
import "../assets/scss/styles.scss";
import {useTranslation} from "react-i18next";
import Services from "./services";
import Examples from "./examples";


function App(props) {
  const { t } = useTranslation();
  return (
    <ThemeProvider>
      <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "c31ea58ab1d446f482f65f6657be6400"}'></script>
      <Router>
        <>
          <Navbar
            color="default"
            logo={logoDark}
            logoInverted={logoLight}
          />
          <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "c31ea58ab1d446f482f65f6657be6400"}'></script>
          <Switch>
            <Route exact path="/" component={IndexPage} />

            <Route exact path="/about" component={AboutPage} />

            {/*<Route exact path="/faq" component={FaqPage} />*/}

            <Route exact path="/contact" component={ContactPage} />
            <Route
                path="/quote"
                render={(props) => <ContactPage {...props} quote={true} />}
            />

            <Route exact path="/pricing" component={PricingPage} />

            <Route exact path="/legal/:section" component={LegalPage} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/examples" component={Examples} />



            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            bgColor="light"
            size="normal"
            bgImage=""
            bgImageOpacity={1}
            description={t('footer.description')}
            copyright={`© ${new Date().getFullYear()} Vapor Signs`}
            logo={logoDark}
            logoInverted={logoLight}
            sticky={true}
          />
        </>
      </Router>
    </ThemeProvider>
  );
}

export default App;
