import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";
import googleAnalyticsPlugin from "@analytics/google-analytics";
import { history } from "./router.js";

// Initialize analytics and plugins
// Documentation: https://getanalytics.io
const analytics = Analytics({
  // debug: process.env.NODE_ENV !== "production",
  plugins: [
    // Instructions: https://divjoy.com/docs/segment
    segmentPlugin({
      writeKey: 'BKrOjuqyfryC0zHwirLTuoZWK3VmpJMG',
    }),
    googleAnalyticsPlugin({
      measurementIds: ['G-HR9THJF0N2'],
    }),
  ],
});

// Track initial pageview
if (typeof window !== "undefined") {
  analytics.page();
}

// Track pageview on route change
history.listen(() => {
  analytics.page();
});

export default analytics;
