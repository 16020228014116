import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon  from "@material-ui/icons/WbSunny";
import PhoneIphoneSharp from "@material-ui/icons/Phone";
import Mail from "@material-ui/icons/Mail";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { Link } from "./../util/router";
import { useDarkMode } from "./../util/theme";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 85,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  etsyText: {
    color: "#eb6d20",
    fontWeight:"bold",
  },
  nav: {
    color: "#5c0000",
    fontWeight:"bold",
  },
  lineTextTheme: {
    fontSize: "medium",
  },
  spacer: {
    flexGrow: 1,
  },
}));

function Navbar(props) {
  const classes = useStyles();

  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  return (
    <Section style={{paddingBottom:'10px'}} bgColor={props.color} size="auto">
      <AppBar position="static" color="transparent" elevation={0}>
        <Container disableGutters={true}>
          <Toolbar>
            <Link to="/">
              <img src={logo} alt="Logo" className={classes.logo} />
            </Link>
            <div className={classes.spacer} />
            <Hidden smUp={true} implementation="css">
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden xsDown={true} implementation="css">
            <Button
                color="inherit"
                component="a"
                href="tel:970-541-2350"
            >
              <PhoneIphoneSharp fontSize="small"/> 970-541-2350
            </Button>
              <Button
                  color="inherit"
                  component="a"
                  href="/services"
              >
                Services
              </Button>
              <Button
                  color="inherit"
                  component="a"
                  href="/quote"
              >
                Get a Quote
              </Button>
              <Button
                  color="inherit"
                  component="a"
                  href="/about"
              >
                Meet the team
              </Button>
              <Button
                  color="inherit"
                  component="a"
                  href="/contact"
              >
                Contact Us
              </Button>
              <Button
                color="inherit"
                target="_blank"
                component="a"
                href="https://vaporsigns.etsy.com/"
              >
                Shop on <span className={classes.etsyText}>&nbsp;Etsy</span>
              </Button>
              <IconButton
                color="inherit"
                onClick={darkMode.toggle}
                style={{ opacity: 0.6 }}
              >
                {darkMode.value && <NightsStayIcon />}

                {!darkMode.value && <WbSunnyIcon />}
              </IconButton>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.drawerList}
          onClick={() => setDrawerOpen(false)}
        >

          <ListItem>
            <IconButton
              color="inherit"
              onClick={darkMode.toggle}
              style={{ opacity: 0.6 }}
            >
              {darkMode.value && <NightsStayIcon />}

              {!darkMode.value && <WbSunnyIcon />}
              <span className={classes.lineTextTheme}>&nbsp;Toggle Theme</span>
            </IconButton>
          </ListItem>
          <ListItem
              button={true}
              component="a"
              href="https://vaporsigns.etsy.com/"
              target="_blank"
          >
            <ListItemText> - Shop on<span className={classes.etsyText}>&nbsp;Etsy</span></ListItemText>
          </ListItem>
          <ListItem
              button={true}
              component="a"
              href={"/services"}
              target="_blank"
          >
            <ListItemText> - Some of our Services</ListItemText>
          </ListItem>
          <ListItem
              button={true}
              component="a"
              href={"/contact"}
          >
            <ListItemText>- Send us a message</ListItemText>
          </ListItem>
          <ListItem
              button={true}
              component="a"
              href={"/about"}
          >
            <ListItemText> - About Us</ListItemText>
          </ListItem>
          <ListItem
              button={true}
              component="a"
              href={"/contact"}
          >
            <Mail fontSize="small"/>
            <ListItemText style={{paddingLeft: '10px'}}> sales@vaporsigns.com</ListItemText>

          </ListItem>
          <ListItem
              button={true}
              component="a"
              href="tel:970-541-2350"
          >
            <PhoneIphoneSharp fontSize="small"/>
            <ListItemText style={{paddingLeft: '10px'}}> 970-541-2350</ListItemText>

          </ListItem>
        </List>
      </Drawer>
    </Section>
  );
}

export default Navbar;
